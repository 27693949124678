// tslint:disable-next-line:max-line-length
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme, withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';

const styles = (theme: Theme) => createStyles({
    wrapper: {
        
    },
});

interface DialogBoxProps extends WithStyles<typeof styles> {
    className?: string;
    isAgree?: (agree: boolean) => void;
    ariaLabelledBy?: string;
    ariaDescribedBy?: string;
    title?: string;
    disAgreeLabel?: string;
    agreeLabel?: string;
    content?: string;
    show: boolean;
    alert?: React.ReactNode;
    disablePortal?: boolean;
    hideDisagree?: boolean;
}

// tslint:disable-next-line:no-empty-interface
interface DialogBoxStates {
    show: boolean;
}

class DialogBox extends React.Component<DialogBoxProps, DialogBoxStates> {
    // tslint:disable-next-line:no-any
    constructor(props: DialogBoxProps, context?: any) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.state = {
            show: this.props.show
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: DialogBoxProps) {
        this.setState({show: nextProps.show});
    }

    public render() {
        // const { classes } = this.props;
        if (!this.props.show) {
            return null;
        }
        return (
           <React.Fragment>
                {/* <div className={classes.wrapper + ' ' + this.props.className}> */}
                    <Dialog
                        open={this.state.show}
                        onClose={this.handleClose}
                        aria-labelledby={this.props.ariaLabelledBy}
                        aria-describedby={this.props.ariaDescribedBy}
                        maxWidth="sm"
                        fullWidth={true}
                        disablePortal={this.props.disablePortal}
                    >
                        <DialogTitle id={this.props.ariaLabelledBy}>{this.props.title}</DialogTitle>
                        <DialogContent>
                            {this.props.children}
                            <DialogContentText id={this.props.ariaDescribedBy}>
                                {this.props.content}
                            </DialogContentText>
                            {this.props.alert}
                        </DialogContent>
                        <DialogActions>
                            {!this.props.hideDisagree && this.handleClose &&
                                <Button onClick={this.handleClose} color="primary">
                                {this.props.disAgreeLabel ? this.props.disAgreeLabel : 'No'}
                                </Button>
                            }
                            <Button onClick={this.handleOk} color="primary" autoFocus={true}>
                            {this.props.agreeLabel ? this.props.agreeLabel : 'Yes'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                {/* </div> */}
           </React.Fragment>
        );
    }

    private handleClose(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ show: false });
        if (this.props.isAgree) {
            return this.props.isAgree(false);
        }
    }

    private handleOk(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ show: false });
        if (this.props.isAgree) {
            return this.props.isAgree(true);
        }
    }
}

// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(DialogBox as any) as any;