import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { client } from '../..';

export interface ReferrerDataParams {
    nameId: number | null;
}

export interface ReferrerData {
    partydetails: Partydetails;
}

export interface Partydetails {
    __typename:                     string;
    id:                             number;
    referrerName:                   string;
    relationshipManagerId:          number;
    relationshipManager:            string;
    relationshipQualityGuid:        string;
    relationshipQualityDescription: string;
    relationshipQualityLevel:       string;
    referrerTypeGuid:               string;
    referrerType:                   string;
    referrerSourceGuid:             string;
    referrerSource:                 string;
    lastContactDate:                Date;
    lastReferralDate:               Date;
    referrerStats:                  ReferrerStats;
    referralsInLastTwelveMonths:    ReferralsInLastTwelveMonths;
    referralsByYear:                ReferralsByYear;
    referralMattersByCategory:      ReferralMattersByCategory;
    referralAverageBilledFees:      ReferralAverageBilledFees;
}

export interface ReferralAverageBilledFees {
    groupedByCount:             number;
    averageBilledFeesPerMatter: number;
    averageBilledFee:           AverageBilledFee[];
}

export interface AverageBilledFee {
    amount:    number;
    groupedBy: string;
}

export interface ReferralMattersByCategory {
    referralMatterCategory: ReferralMatterCategory[];
}

export interface ReferralMatterCategory {
    group: string;
    count: number;
}

export interface ReferralsByYear {
    referralMattersByYear:          ReferralMattersByYear[];
    referralMatterBilledFeesByYear: ReferralMatterBilledFeesByYear[];
}

export interface ReferralMatterBilledFeesByYear {
    year:  string;
    billedFees: number;
}

export interface ReferralMattersByYear {
    year:  string;
    count: number;
}

export interface ReferralsInLastTwelveMonths {
    referralMattersByMonth:          ReferralMattersByMonth[];
    referralMatterBilledFeesByMonth: ReferralMatterBilledFeesByMonth[];
}

export interface ReferralMattersByMonth {
    count:  number;
    month: string;
}

export interface ReferralMatterBilledFeesByMonth {
    billedFees:  number;
    month: string;
}

export interface ReferrerStats {
    id:                                     number;
    initialContactDate:                     Date;
    lastMatterDate:                         Date;
    lastContactDate:                        Date;
    clientsReferredInLast12Months:          number;
    mattersReferredInLast12Months:          number;
    relatedFeesInLast12Months:              number;
    clientsReferredInTotal:                 number;
    mattersReferredInTotal:                 number;
    relatedFeesInTotal:                     number;
    enquiriesReferredInTotal:               number;
    enquiriesReferredInTheLast12Months:     number;
    lastEnquiryDate:                        Date;
    enquiriesReferredToLast12Months:        number;
    enquiriesReferredToTotal:               number;
    lastEnquiryReferredTo:                  Date;
}

export function retrieveReferrerDetailsData(
    query: ReferrerDataParams, 
    refreshData: boolean,
    onSuccess: (data: ReferrerData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
     : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
        client.query({
            query: ReferrerDetailsData,
            variables: {
                nameId: query.nameId
            },
            fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { 
        onError(reason); 
    });
}

export const ReferrerDetailsData = gql`
    query ReferrerDetailsData($nameId: Int!){
        partydetails(nameId: $nameId) {
            __typename
            ... on Referrer {
                id
                referrerName,
                relationshipManagerId,
                relationshipManager,
                relationshipQualityGuid,
                relationshipQualityDescription,
                relationshipQualityLevel,
                referrerTypeGuid,
                referrerType,
                referrerSourceGuid,
                referrerSource,
                lastContactDate,
                lastReferralDate
                
                referrerStats{
                    id,
                    initialContactDate,
                    lastMatterDate,
                    lastContactDate,
                    clientsReferredInLast12Months,
                    mattersReferredInLast12Months,
                    relatedFeesInLast12Months,
                    clientsReferredInTotal,
                    mattersReferredInTotal,
                    relatedFeesInTotal,
                    enquiriesReferredInTotal,
                  	enquiriesReferredInTheLast12Months,
                    lastEnquiryDate,
                    enquiriesReferredToLast12Months,
                    enquiriesReferredToTotal,
                    lastEnquiryReferredTo
                }
                referralsInLastTwelveMonths(hasInvoices:true){
                    referralMattersByMonth{
                        month,
                        count
                    }
                    referralMatterBilledFeesByMonth{
                        billedFees,
                        month
                    }              
                }
                
                referralsByYear(hasInvoices:true){            
                    referralMattersByYear{
                        year,
                        count              
                    }            
                    referralMatterBilledFeesByYear{
                        year,
                        billedFees
                    }
                }
                
                referralMattersByCategory{
                    referralMatterCategory{
                        group,
                        count
                    }
                }
                    
                referralAverageBilledFees{
                    groupedByCount,
                    averageBilledFeesPerMatter,
                    averageBilledFee{
                        amount,
                        groupedBy
                    }
                }        
            }
        }
    }
`;

export interface CurrencySymbolData {
    options: Options;
}

export interface Options {
    currencySymbol: CurrencySymbol;
}

export interface CurrencySymbol {
    symbol: string;
}

export function retrieveCurrencySymbolData(
    refreshData: boolean,
    onSuccess: (data: CurrencySymbolData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
     : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
        client.query({
            query: CurrencySymbol,
            variables: {},
            fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { 
        onError(reason); 
    });
}

const CurrencySymbol = gql`query CurrencySymbol {
    options {
        currencySymbol {
            symbol
        }
    }
}`;