import { EventEmitter } from 'events';

/* tslint:disable:jsx-wrap-multiline */
class EventSystem {

    public static event: EventEmitter;

    // tslint:disable-next-line:member-ordering
    static getInstance() {
        if (!EventSystem.event) {
            EventSystem.event = new EventEmitter();
        }
        return EventSystem.event;
    }
    
    // tslint:disable-next-line:no-any
    static on(event: string | symbol, listener: (...args: any[]) => void) {
        EventSystem.event.on(event, listener);
    }
    
    // tslint:disable-next-line:no-any
    static emit(event: string | symbol, ...args: any[]) {
        EventSystem.event.emit(event);
    }

    public constructor() {
        //  code here
    }

}

export default EventSystem;