import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { client } from '..';

export type ClientAndContactDetailsData = {
    partydetails: Partydetails;
    partyWebsite: PartyWebsite;
    options:      Options;
    appUser:      AppUser;
};

export type AppUser = {
    isInternal: boolean;
};

export type Options = {
    matterOptions: MatterOptions;
};

export type MatterOptions = {
    canShowGeneral:    boolean;
    canShowFinancials: boolean;
    canShowDocuments:  boolean;
};

export type PartyWebsite = {
    __typename: string;
    id:         number;
    website:    string;
    // individual
    lastName:   string;
    // organistion
    longName:   string | null;
};

export type Partydetails = {

    // common
    __typename:           string;
    id:                   number;
    guid:                 string;
    nameNotes:            string;
    isFromDataSource:           boolean;
    hasMarketingConsent:  boolean;
    phoneNumbers:         PhoneNumber[];
    addresses:            Address[];
    emailAddresses:       EmailAddress[];
    // tslint:disable-next-line: no-any
    relatedIndividuals:   any[];
    // tslint:disable-next-line: no-any
    relatedOrganisations: any[];
    matters:              Matters;
    mattersReferred:      MattersReferred;
    partyType:            PartyType;
    tags:                 Tag[];
    documentCount:        DocumentCount;

    // individual
    lastName:             string | null;
    firstName:            string | null;
    middleName:           string | null;
    dateOfBirth:          Date | null;
    displayName:          string | null;
    
    // organisation
    longName:             string | null;
};

export type Address = {
    type:     string;
    sequence: number;
    display:  string;
};

export type DocumentCount = {
    partyDocumentTotalCount: number;
};

export type EmailAddress = {
    partyId:  number;
    type:     string;
    email:    string;
    sequence: number;
};

export type Matters = {
    totalCount:       number;
    totalMatterCount: number;
};

export type MattersReferred = {
    totalCount: number;
};

export type PartyType = {
    isClient:       boolean;
    isIndividual:   boolean;
    isOrganisation: boolean;
    isReferrer:     boolean;
    isStaff:        boolean;
    isUser:         boolean;
};

export type PhoneNumber = {
    partyId:   number;
    type:      string;
    number:    string;
    extension: null;
    sequence:  number;
};

export type Tag = {
    guidId:    string;
    tag:       string;
    tagGuidId: string;
};

export const ClientAndContactDetailsDataQuery = gql`
    query ReferrerDetailsData($matterId: Int!, $nameId: Int!, $isFromDataSource: Boolean) {
        partydetails(nameId: $nameId, isFromDataSource: $isFromDataSource) {
            __typename
            ... on Staff {
                id
                guid
                firstName
                lastName
                nameNotes
                isFromDataSource
                middleName
                dateOfBirth
                displayName
                hasMarketingConsent
                phoneNumbers {
                    partyId
                    type
                    number
                    extension
                    sequence
                }
                addresses {
                    type
                    sequence
                    display
                }
                emailAddresses {
                    partyId
                    type
                    email
                    sequence
                }
                relatedIndividuals {
                    id
                }
                relatedOrganisations {
                    id
                }
                matters {
                    totalCount
                    totalMatterCount
                }
                mattersReferred {
                    totalCount
                }
                partyType {
                    isClient
                    isIndividual
                    isOrganisation
                    isReferrer
                    isStaff
                    isUser
                }
                tags {
                    guidId
                    tag
                    tagGuidId
                }
                documentCount {
                    partyDocumentTotalCount
                }
            }
            ... on Individual {
                id
                guid
                lastName
                firstName
                nameNotes
                isFromDataSource
                middleName
                dateOfBirth
                displayName
                hasMarketingConsent
                phoneNumbers {
                    partyId
                    type
                    number
                    extension
                    sequence
                }
                addresses {
                    type
                    sequence
                    display
                }
                emailAddresses {
                    partyId
                    type
                    email
                    sequence
                }
                relatedIndividuals {
                    id
                }
                relatedOrganisations {
                    id
                }
                matters {
                    totalCount
                    totalMatterCount
                }
                mattersReferred {
                    totalCount
                }
                partyType {
                    isClient
                    isIndividual
                    isOrganisation
                    isReferrer
                    isStaff
                    isUser
                }
                tags {
                    guidId
                    tag
                    tagGuidId
                }
                documentCount {
                    partyDocumentTotalCount
                }
            }
            ... on Organisation {
                id
                guid
                longName
                nameNotes
                isFromDataSource
                hasMarketingConsent
                phoneNumbers {
                    partyId
                    type
                    number
                    extension
                    sequence
                }
                addresses {
                    type
                    sequence
                    display
                }
                emailAddresses {
                    partyId
                    type
                    email
                    sequence
                }
                relatedIndividuals {
                    id
                }
                relatedOrganisations {
                    id
                }
                matters {
                    totalCount
                    totalMatterCount
                }
                mattersReferred {
                    totalCount
                }
                partyType {
                    isClient
                    isIndividual
                    isOrganisation
                    isReferrer
                    isStaff
                    isUser
                }
                tags {
                    guidId
                    tag
                    tagGuidId
                }
                documentCount {
                    partyDocumentTotalCount
                }
            }
        }
        partyWebsite: partydetails(nameId: $nameId, isFromDataSource: $isFromDataSource) {
            __typename
            ... on Staff {
                id
                lastName
                website
            }
            ... on Individual {
                id
                lastName
                website
            }
            ... on Organisation {
                id
                longName
                website
            }
        }
        options {
            matterOptions(matterId: $matterId) {
                canShowGeneral
                canShowFinancials
                canShowDocuments
            }
        }
        appUser {
            isInternal
        }
    }
`;

export const nameAccessActivityMutation = gql`
    mutation AddUserPartyActivityAccess ($nameId: Int, $nameGuid: String, $isFromDataSource: Boolean) {
        addUserPartyAccessActivity (nameId: $nameId, nameGuid: $nameGuid, isFromDataSource: $isFromDataSource)
    }
`;

const PoBoxTypeQuery = gql`
    query PoBoxType{
        lookup {
            poBoxType{
                id,
                guidId,
                description,
                sequence
            }
        }
    }
`;

export type PoBoxTyeData = {
    lookup: Lookup;
};

export type Lookup = {
    poBoxType: PoBoxType[];
};

export type PoBoxType = {
    id:          number;
    guidId:      string;
    description: string;
    sequence:    number;
};

export function fetchPOBoxTypeData(
    refreshData: boolean,
    onSuccess: (data: PoBoxTyeData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: PoBoxTypeQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export type DeletePartyImageParams = {
    partyGuid: string;
    isFromDataSource: boolean;
};

export type DeletePartyImageData = {
    crm: CRM;
};

type CRM = {
    party: Party;
};

type Party = {
    deletePartyImage: DeletePartyImage;
};

type DeletePartyImage = {
    error:  null;
    status: boolean;
};

export const DeletePartyImageMutation = gql`
    mutation DeletePartyImage($partyGuid: String, $isFromDataSource: Boolean) {
        crm {
            party {
                deletePartyImage(partyGuid: $partyGuid, isFromDataSource: $isFromDataSource) {
                    error
                    status
                }
            }
        }
    }
`;