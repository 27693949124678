import * as React from 'react';
import * as H from 'history';
import './App.css';
import { AppContent } from './components/AppContent';
import SvgIcons from './SvgIcons';
import EventSystem from './EventSystem';

// property is fetched from "config/webpack.config.{env}.js" file, under plugins section
declare const GraphQLApiUrl: string;
export const serverUrl = GraphQLApiUrl;
declare const CDNUrl: string;
export const cdnUrl = CDNUrl ? CDNUrl : process.env.PUBLIC_URL;

export interface RouterHistoryProps {
  history: H.History;
}

export interface RouterProps extends RouterHistoryProps {
  match: {
    params: {
      tenantId: string;
      id: string;
      command: string;
      tab: string;
      filter: string;
    };
  };
}

interface AppProps {
  page?: {};
}

interface AppState {
  loggedOn: boolean;
}

export function linkToPage(page: string) {
  return '/' + page;
}

class AppStateClass {
  public loggedOn: boolean;
  private app: App;

  constructor() {
    this.loggedOn = false;
  }

  public registerApp(app: App) {
    this.app = app;
  }

  public updateAppState(appState: AppState) {
    this.app.setState(appState);
  }

  public getAppState(): AppState {
    return this.app.state;
  }

  /*   public forceUpdate(): void {
    this.app.forceUpdate();
  } */
}

export const AppStateUpdate = new AppStateClass();

export class App extends React.Component<AppProps, AppState> {
  // tenantId: string = '';

  constructor(props: AppProps, state: AppState) {
    super(props, state);
    // this.tenantId = window.location.href.split('/')[window.location.href.split('/').length - 1];
    this.state = {
      loggedOn: false
    };
    AppStateUpdate.registerApp(this);
    SvgIcons.getInstance();
    EventSystem.getInstance();
  }

  // componentDidMount() {
  //   this.setupBeforeUnloadHandler();
  // }

  // componentWillUnmount() {
  //   this.removeBeforeUnloadHandler();
  // }

  // setupBeforeUnloadHandler = () => {
  //   window.addEventListener('beforeunload', this.handleBeforeUnload);
  // };

  // removeBeforeUnloadHandler = () => {
  //   window.removeEventListener('beforeunload', this.handleBeforeUnload);
  // };

  // handleBeforeUnload = (e: BeforeUnloadEvent) => {
  //   const message = 'Changes you made may not be saved.';
  //   e.preventDefault();
  //   e.returnValue = message;
  //   return message;
  // };

  render() {
    // var accessToken = sessionStorage.getItem('accessToken');
    // let isAuthenticated = (accessToken != null && accessToken !== '');
    /*   if (!this.tenantId) {
      window.location.replace('http://www.redview.com.au/');
      return <div>Redirecting to RedView....</div>;
    } */

    return (
      <React.Fragment>
        <AppContent
          // tslint:disable-next-line:no-any
          notificationSystem={(item: any) => (notificationSystem = item)}
        />
      </React.Fragment>
    );
  }

}

export default App;
// tslint:disable-next-line:no-any
let notificationSystem: any;

export function showNotification(
  title: string | null,
  message: string,
  level: 'error' | 'warning' | 'info' | 'success',
  msgID?: string
) {
  let autoDismiss: number;
  switch (level) {
    case 'error':
      autoDismiss = 0;
      break;
    default:
      autoDismiss = 10;
  }
  notificationSystem.addNotification({
    title: title,
    message: message,
    level: level,
    autoDismiss: autoDismiss,
    uid: msgID
  });
  switch (level) {
    case 'error':
      // tslint:disable-next-line:no-console
      console.error(message);
      break;
    case 'warning':
      // tslint:disable-next-line:no-console
      console.warn(message);
      break;
    case 'info':
      // tslint:disable-next-line:no-console
      console.info(message);
      break;
    default:
      // tslint:disable-next-line:no-console
      console.log(message);
  }
}
